import React from 'react';
import Card from 'react-bootstrap/Card';

import PDF from "../../static/PUERPERIO.pdf"
import PDFmaternidad from "../../static/MATERNIDAD.pdf"

export function Pdf(){
  return (
  <div style={{display:"flex", justifyContent:"flex-start", alignItems:"center", height:"100vh", flexDirection:"column"}}>
    <div style={{display:"flex", justifyContent:"center", alignItems:"flex-start"}}>
    <Card style={{ width: '18rem', marginTop: "60px", textAlign:"center" }}>
      <Card.Header>Perpuerio PDF</Card.Header>
      <Card.Body>
        <Card.Text>
          <strong>Hacer click en el siguiente link para comenzar la descarga</strong>
          <p> </p>
          <a href={PDF} download="Puerperio PDF">Descargar PDF</a>
        </Card.Text>
      </Card.Body>
    </Card>
    </div>
    <div style={{display:"flex", justifyContent:"center", alignItems:"flex-start"}}>
    <Card style={{ width: '18rem', marginTop: "60px", textAlign:"center" }}>
      <Card.Header>Maternidad PDF</Card.Header>
      <Card.Body>
        <Card.Text>
          <strong>Hacer click en el siguiente link para comenzar la descarga</strong>
          <p> </p>
          <a href={PDFmaternidad} download="Maternidad PDF">Descargar PDF</a>
        </Card.Text>
      </Card.Body>
    </Card>
    </div>
  </div>
  )
}

