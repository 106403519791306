/* UI */
import { HeaderContainer } from "../UI/container";
import { NavContainer } from "../UI/container";
import { HeaderTab } from "../UI/tabs";

/* Bootstrap */
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

/* Logo */

import { ImgLogo } from "../UI/img";

export function HomeHeader({infoSectionReference, titleSectionReference, promptSectionReference, legalSectionReference, questSectionReference}) {


    function infoSectionOnClick(){
      infoSectionReference.current?.scrollIntoView({ behavior: "smooth", block:"start" });
    }
    function titleSectionOnClick(){
      titleSectionReference.current?.scrollIntoView({ behavior: "smooth", block:"start" });
    }
    function promptSectionOnClick(){
      promptSectionReference.current?.scrollIntoView({ behavior: "smooth", block:"start" });
    }
    function legalSectionOnClick(){
      legalSectionReference.current?.scrollIntoView({ behavior: "smooth", block:"start" });
    }
    function questSectionOnClick(){
      questSectionReference.current?.scrollIntoView({ behavior: "smooth", block:"start" });
    }
    return (
      <> 
         <HeaderContainer>
            <ImgLogo></ImgLogo>
          <NavContainer>
            <Navbar  bg="light" data-bs-theme="dark"  className="bg-body-tertiary">
              {/* <Navbar.Toggle aria-controls="navbar" /> */}
                 
                  <Nav variant="tabs" defaultActiveKey="/home" style={{ display:"flex", justifyContent:"center", borderBottom:"2px solid black"}}as="ul">
                    <Nav.Item as="li" style={{marginBottom:"1px"}}>
                      <HeaderTab onClick={titleSectionOnClick}>Inicio</HeaderTab>
                    </Nav.Item>
                    <Nav.Item as="li">
                      <HeaderTab  onClick={()=>{infoSectionOnClick()}}>Información</HeaderTab>
                    </Nav.Item>
                    <Nav.Item as="li">
                      <HeaderTab  onClick={()=>{promptSectionOnClick()}}>Sugerencias</HeaderTab>
                    </Nav.Item>
                    <Nav.Item as="li">
                      <HeaderTab  onClick={()=>{legalSectionOnClick()}}>Derechos Legales</HeaderTab>
                    </Nav.Item>
                    <Nav.Item as="li">
                      <HeaderTab  onClick={()=>{questSectionOnClick()}}>Encuesta</HeaderTab>
                    </Nav.Item>
                  </Nav>
                 
            </Navbar> 
          </NavContainer>
        </HeaderContainer>
      </>
    );
  }