import { styled } from "styled-components"


function Container({ className, children, myref }) {

  return (
    <div ref={myref} className={className}>{children}</div>
  );
}

export const HomeContainer = styled(Container)`
   width: 100vw;

   `
export const HomeImageContainer = styled(Container)`
      
      width: 40vw;
      height: 390px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      border: solid 1px black;
      border-radius: 0px 40px 40px 0px;
      border-left: none;
        @media screen and (max-width: 768px){
          height: 260px
        }
   `

export const TitleSectionContainer = styled(Container)`
      
      background-color: rgb(148, 219, 236);
      height: 650px;
      width: 100vw;
      margin-top: 43px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      

      @media screen and (max-width: 1440px){
      height: 650px;
     }
      @media screen and (max-width: 768px){
        margin-top: 110px;
        height: 965px;
      }
      @media screen and (max-width: 440px){
        margin-top: 110px;
        height: 850px;
      }
      @media screen and (max-width: 380px){
        margin-top: 110px;
        height: 1075px;
      }

    `
export const TitleSectionTitlesContainer = styled(Container)`
    display: flex;
    width: 100%;
    height: 320px;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    border: solid 1px #02adf5;
    border-radius: 40px ;
    background-color: #02adf5;
    margin-top: 50px;
    @media screen and (max-width: 768px){
      height: 270px;
      margin-top: 20px;
      width: 90%;
      margin: auto;
     }
     @media screen and (max-width: 440px){
      height: 200px;
    }
      
  `
export const TitleSectionArrowContainer = styled(Container)`
    width: 100%;
    height:225px;
    border:solid 1px black;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    background-color: black;
    margin-top: 10px;
    @media screen and (max-width: 990px){
      height: 169px;
      width: 79%;
      margin-top: 35px;
      margin-left: 30px;
     }
    @media screen and (max-width: 768px){
      width: 100%;
      height: 225px;
      margin-top: 10px;
      margin-left: 0;
     }
    @media screen and (max-width: 440px){
      margin: auto;
      width: 90%;
      margin-top: 10px;
     }
`
export const TitleSectionMoreInfoContainer = styled(Container)`
    width:100%;
    height:225px;
    border:solid 1px #1c3843;
    border-radius: 40px;
    background-color: #1c3843;
    margin-top: 10px;
    display: flex;
    align-items: center;
    @media screen and (max-width: 990px){
      width:125%;
     }
    @media screen and (max-width: 768px){
      width:100%;
     }
    
`
export const TitleSectionHospiImgCointener = styled(Container)`

    width:95%; 
    height:550px;
    border:solid 1px transparent;
    margin-top:50px;
    border-radius: 40px; 
    @media screen and (max-width: 1200px){
      background-color: #94dbec;
      display: flex;
      align-items: center;
     }
    @media screen and (max-width: 768px){
      height: 380px;
      margin-top: 10px;
      margin-left: 12px;
     }
    @media screen and (max-width: 440px){
      height: 320px;
     }

   
`
export const HeaderContainer = styled(Container)`
    display: flex;
    width: 100vw;
    height: 80px;
    background-color: #f8f9fa;
    align-items: center;
    justify-content: space-around;
    position: fixed;
    top: 0px;
    z-index: 2;
    

    @media screen and (max-width: 1024px){
      height: 80px;
    }
    @media screen and (max-width: 768px){
      flex-direction: column;
      height: 130px;
    }
  `
export const NavContainer = styled(Container)`
    
  `

export const InfoSectionContainer = styled(Container)`
    width: 100vw;
    height: 715px;
    background-color: #02adf5;
    @media screen and (max-width: 990px){
      height: 790px;
    }
    @media screen and (max-width: 768px){
      height: 880px;
    }
    @media screen and (max-width: 575px){  
      height: 1150px;
    }
    @media screen and (max-width: 430px){  
      height: 1300px;
    }
    @media screen and (max-width: 310px){  
      height: 1420px;
    }
    `
export const InfoSectionTitlesContainer = styled(Container)`
  display: flex;
  width: 100vw;
  height: 170px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
export const InfoSectionCardsContainer = styled(Container)`
     
  `
export const InfoSectionInfoImgContainer = styled(Container)`
     width: 300px;
     height: 400px;
     margin: auto;
     margin-top: 20px;
     @media screen and (max-width: 768px){
      width: 260px;
      margin-top: 60px;
     }
     @media screen and (max-width: 680px){
      width: 220px;
      margin-top: 80px;
     }
  `

export const LinksSectionContainer = styled(Container)`
     display: flex;
     justify-content: center;
     align-items: center;
     flex-direction: column;
     height: 840px;
     background-color: #1c3843;
     @media screen and (max-width: 768px){
      height: 720px;
     }
     @media screen and (max-width: 640px){
      height: 1110px;
     }
  `
export const LinksSectionPart1Container = styled(Container)`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
  `
export const LinksSectionPart2Container = styled(Container)`
     display: flex;
     @media screen and (max-width: 768px){
      flex-direction: column;
     }
     
  `
export const LinksSectionTitlesContainer = styled(Container)`
  display: flex;
  width: 235px;
  height: 50px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-bottom: solid 1px aliceblue;
  margin-bottom: 10px;
`
export const LinksContainer = styled(Container)`
    width: 300px;
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
  `