import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import pdf from "../static/PUERPERIO.pdf"


export function App() {
    const navigate = useNavigate()

    useEffect(()=>{
      navigate("/UTI") 
    },[])
    return (
      <h1></h1>
      )
  }