import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export function ModalSupplies({ handleClose, show}){
    return (
        <>    
          <Modal show={show} onHide={handleClose} centered size='sm'>
            <Modal.Header closeButton>
              <Modal.Title>Insumos necesarios para la atención de su familiar</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <strong>Cuando ingresa su familiar a terapia intensiva le solicitamos de forma semanal los insumos abajo solicitados:</strong>
                <p></p>
                <p>-Elementos de higiene</p>
                <p>Enjuague bucal con clorexidina (placout, periobacter)</p>
                <p>Cepillo de dientes pediatrico</p>
                <p>Desodorante aerosol</p>
                <p>Shampoo y acondicionador</p>
                <p>Peine</p>
                <p>Maquina de afeitar</p>
                <p>Crema corporal humectante (dermaglos, crema base, factor AE emulsionante)</p>
                <p>Pañales adultos</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      );
}