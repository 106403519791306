import { TitleSectionContainer, TitleSectionTitlesContainer, TitleSectionArrowContainer, TitleSectionMoreInfoContainer,TitleSectionHospiImgCointener } from "../UI/container";
import { TitleSectionTitle, TitleSectionParagraph, TitleMoreInfo, TitleMoreInfoTitle } from "../UI/title";

import { ImgArrow, ImgHospi } from "../UI/img";
import { Container, Row, Col } from 'react-bootstrap';

import { styled } from "styled-components";

const OwnRow = styled(Row)`
  @media screen and (max-width: 768px){
      margin: auto;
      display: flex;
     }
  @media screen and (max-width: 380px){
      margin: auto;
      display: flex;
      flex-direction: column
     }
`
const OwnCol = styled(Col)`
  @media screen and (max-width: 768px){
      width: 50%;
     }
  @media screen and (max-width: 380px){
      width: 100%;
     }
`

export function TitleMovil({titleSectionRef}) {
    return (
      <>
        <TitleSectionContainer myref={titleSectionRef}>
        <Container>
          <Row>
            <Col md={6}>
              <Row>
                <Col>
                  <TitleSectionTitlesContainer>
                    <TitleSectionTitle> Hospital Zonal</TitleSectionTitle>
                    <TitleSectionTitle> Dr Ramón Carrillo</TitleSectionTitle>
                    <TitleSectionParagraph>Servicio de Terapia Intensiva</TitleSectionParagraph>
                  </TitleSectionTitlesContainer>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <TitleSectionHospiImgCointener ><ImgHospi></ImgHospi></TitleSectionHospiImgCointener>
            </Col>
            <OwnRow>
                <OwnCol md={6}>
                  <TitleSectionMoreInfoContainer>
                    {/* <TitleMoreInfoTitle>Bienvenido!</TitleMoreInfoTitle> */}
                    <TitleMoreInfo>Aquí encontraras toda la información respecto a nuestro servicio</TitleMoreInfo>
                  </TitleSectionMoreInfoContainer>
                </OwnCol>
                <OwnCol md={6}>
                  <TitleSectionArrowContainer ><ImgArrow></ImgArrow></TitleSectionArrowContainer>
                </OwnCol>
            </OwnRow>
          </Row>
        </Container>
      </TitleSectionContainer>
      </>
    );
  }