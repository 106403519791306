/* Bootstrap */
import Nav from 'react-bootstrap/Nav';

import { styled } from 'styled-components';

function Nav({ className, onClick }) {
    return (
        <Nav.Link className={className} onClick={() => { onClick() }}></Nav.Link>
    );
}

export const HeaderTab = styled(Nav.Link)`
    margin:5px;
    font-size: 15px;
    color:black;
    @media screen and (max-width: 1024px){
        font-size: 13px;
    }
    @media screen and (max-width: 768px){
      font-size: 12px;
    }
    @media screen and (max-width: 425px){
      font-size: 10px;
    }
    @media screen and (max-width: 375px){
      font-size: 9px;
    }
    @media screen and (max-width: 320px){
      font-size: 7px;
    }
`
