import * as ImgInfo from "../../images/InfoImg.jpeg"
import * as Logo from "../../images/hospiLogoSvg.svg" 
import * as ArrowImg from "../../images/arrow.svg"
import * as HospiImg from "../../images/Hospi.jpg"

import { styled } from "styled-components";

import { useNavigate } from "react-router-dom";

 function ImgInfoBase() {
    return (
      <img src={ImgInfo} alt="ImgInfo" style={{maxWidth:"100%", height:"auto"}} />
    );
  }
export function ImgArrow() {
    return (
      <img src={ArrowImg} alt="Arrow" style={{maxWidth:"60%", height:"auto"}} />
    );
  }
function ImgHospiBase({className}) {
    return (
      <img className={className} src={HospiImg} alt="Arrow"  />
    );
  }
export function ImgLogoCustom({className}) {

  const navigate = useNavigate()

    return (
      <img className={className} src={Logo} alt="ImgLogo" onClick={()=>{ navigate("/")}} />
    );
  }

export const ImgInfoStyled = styled(ImgInfoBase)`
  max-width: 100%;
  height: auto;
`

export const ImgLogo = styled(ImgLogoCustom)`
  max-width: 100%;
  width: 130px;
  height: 80px;
  &:hover{
    cursor: pointer;
  }
`
export const ImgHospi = styled(ImgHospiBase)`
  max-width: 100%;
  height: 548px;
  border-radius: 40px;
  @media screen and (max-width: 1200px){
      height: 385px;
     }
  @media screen and (max-width: 990px){
      height: 315px;
     }
  @media screen and (max-width: 768px){
      height: 380px;
     }
  @media screen and (max-width: 440px){
      height: 320px;
     }
`