import { styled } from "styled-components";

function Title({ className, children, myRef }) {
  return (
    <h1 ref={myRef} className={className}>{children}</h1>
  );
}
function TitlePrompt({ className, children, myRef }) {
  return (
    <h1 ref={myRef} className={className}>{children}</h1>
  );
}
function TitleMore({ className, children }) {
  return (
    <p className={className}>{children}</p>
  );
}

export const TitleSectionTitle = styled(Title)`
    font-family: 'Lexend', sans-serif;
    font-size: 40px;
    margin-left: 20px;
    color: white;

    @media screen and (max-width: 990px){
      font-size: 30px;
    }
    @media screen and (max-width: 768px){
      font-size: 30px;
      text-align: center;
      width: 100%;
      margin-left: 0;
    }
    @media screen and (max-width: 440px){
      font-size: 24px;
      text-align: center;
    }
    @media screen and (max-width: 320px){
      font-size: 20px;
      text-align: center;
    }
    
  `
export const TitleSectionParagraph = styled(Title)`
    font-family: 'Lexend', sans-serif;
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin-left: 20px;
    margin-top: 22px;

    @media screen and (max-width: 990px){
      font-size: 20px;
    }
    @media screen and (max-width: 768px){
      font-size: 30px;
      text-align: center;
      width: 100%;
      margin-left: 0;
    }
    @media screen and (max-width: 440px){
      font-size: 24px;
      text-align: center;
    }
    @media screen and (max-width: 320px){
      font-size: 20px;
      text-align: center;
    }
  `

export const TitleMoreInfoTitle = styled(TitleMore)`
  font-family: 'Lexend', sans-serif;
  font-size: 22px;
  color: white;
  text-align: center;
  margin-top: 22px;
`
export const TitleMoreInfo = styled(TitleMore)`
  font-family: 'Lexend', sans-serif;
  color: white;
  text-align: center;
  @media screen and (max-width: 990px){
      font-size: 15px;
    }
`
export const InfoSectionTitle = styled(Title)`
    font-family: 'Lexend', sans-serif;
    font-size: 35px;
    border-bottom: solid 1px;
    width: 700px;
    text-align: center;
    padding: 10px;
    @media screen and (max-width: 768px){
      width: 500px;
     }
    @media screen and (max-width: 530px){
      width: 390px;
     }
  `
export const LinkSectionTitle = styled(Title)`
    font-family: 'Lexend', sans-serif;
    font-size: 35px;
    border-bottom: solid 1px;
    width: 700px;
    text-align: center;
    padding: 10px;
    color: aliceblue;
    @media screen and (max-width: 768px){
      width: 550px;
      font-size: 35px;
     }
    @media screen and (max-width: 530px){
      width: 230px;
      font-size: 23px;
     }
  `
export const LinksSectionTitle = styled(Title)`
    font-family: 'Lexend', sans-serif;
    font-size: 22px;
    text-align: center;
    color: aliceblue;
  `
export const LinksSectionTitlePrompt = styled(TitlePrompt)`
    font-family: 'Lexend', sans-serif;
    font-size: 35px;
  `
