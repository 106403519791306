import { InfoSectionContainer } from "../UI/container";
import { InfoSectionCardsContainer } from "../UI/container";
import { InfoSectionTitlesContainer, InfoSectionInfoImgContainer } from "../UI/container";
import { InfoSectionTitle } from "../UI/title";
import { InfoSectionCard2, InfoSectionCard1, InfoSectionCardExtend, InfoSectionCardSuppliesExtend, InfoSectionCard3, InfoSectionCardSupplies, InfoSectionRefCard } from "../UI/cards";
import { ImgInfo } from "../UI/img";

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { styled } from "styled-components";

const Tab1 = styled(Tab)`
  border-radius: 6px 6px 0px 0px;
  background-color: #02adf5 ;
  border: solid 1px;
  height: 392px;
`
const Tab2 = styled(Tab)`
  border-radius: 6px 6px 0px 0px;
  background-color: #02adf5 ;
  border: solid 1px;
  height: 392px;

`
const Tab3 = styled(Tab)`
  border-radius: 6px 6px 0px 0px;
  background-color: #02adf5 ;
  border: solid 1px;
  height: 392px;
`
const Div1 = styled.div`
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 490px){
      flex-direction: column;
      align-items: center;
     }
`
const Div2 = styled.div`
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 490px){
      flex-direction: column;
      align-items: center;
     }
`
const Div3 = styled.div`
    display: flex;
    flex-direction: row;
`

export function InfoSection({infoRef}) {

    return (
      <InfoSectionContainer myref={infoRef}>
        <InfoSectionTitlesContainer>
          <InfoSectionTitle>Información</InfoSectionTitle>
        </InfoSectionTitlesContainer>

        <InfoSectionCardsContainer>
          <Container style={{height:"450px",backgroundColor:"#02adf5"}}>
          <Tabs
              defaultActiveKey="info"
              id="justify-tab-example"
              className="mb-3"
              justify
              style={{borderRadius:"6px 6px 0px 0px", backgroundColor:"#d2d7da"}}
            >
              <Tab1 eventKey="info" title="Información" >
                <Div1 >
                  <InfoSectionCard1 title={"Horario de Visita"} paragraphsTitle={"Todos los días"} paragraphs={"De 15:00hs a 15:30hs"}></InfoSectionCard1>
                  <InfoSectionCard1 title={"Horario de informe médico"} paragraphsTitle={"Todos los días"} paragraphs={"Posterior al horario de visita"}></InfoSectionCard1>
                  <InfoSectionCard1 title={"Telefono Hospital"} paragraphsTitle={"Tel: 02944 - 426100/117"} paragraphs={"Interno: 472"}></InfoSectionCard1>
                </Div1>
                <div>
                  <InfoSectionCardExtend></InfoSectionCardExtend>
                </div>
              </Tab1>
              <Tab2 eventKey="profile" title="Referentes" >
                <Div2 >
                  <InfoSectionCard2 title={"Director del Hospital Zonal Bariloche"} paragraphsTitle={"Don Leonardo Ernesto Gil"} paragraphs={""}></InfoSectionCard2>
                  <InfoSectionCard2 title={"Jefe de departamento médico"} paragraphsTitle={"Dr Fernando Tortosa"} paragraphs={""}></InfoSectionCard2>
                </Div2>
                <Div2 >
                  <InfoSectionCard2 title={"Jefe de enfermería"} paragraphsTitle={"Lic Graciela Perez"} paragraphs={""}></InfoSectionCard2>
                  <InfoSectionCard2 title={"Coordinador médico"} paragraphsTitle={" "} paragraphs={" "}></InfoSectionCard2>
                </Div2>
              </Tab2>
              <Tab3 eventKey="longer-tab" title="Insumos para el paciente" >
                <InfoSectionCardSuppliesExtend></InfoSectionCardSuppliesExtend>
              </Tab3>
            </Tabs>
          </Container>
          
        </InfoSectionCardsContainer>
      </InfoSectionContainer>
    );
  }