import { LinksSectionContainer, InfoSectionInfoImgContainer, InfoSectionTitlesContainer } from "../UI/container";
import { LinksSectionTitlesContainer } from "../UI/container";
import { LinksSectionTitle, LinkSectionTitle } from "../UI/title";
import { LinksContainer, LinksSectionPart1Container, LinksSectionPart2Container } from "../UI/container";
import { LinksSectionTitlePrompt } from "../UI/title";
import { ImgInfoStyled } from "../UI/img";

import Card from 'react-bootstrap/Card';

import pdf from "../../static/Derechos.pdf"


export function LinksSection({promptRef, legalRef, questRef}) {

    return (
      <>
        <LinksSectionContainer myref={promptRef}>

        <InfoSectionTitlesContainer>
          <LinkSectionTitle>Información legal y Encuesta</LinkSectionTitle>
        </InfoSectionTitlesContainer>
        <LinksSectionPart2Container>
          <InfoSectionInfoImgContainer>
              <ImgInfoStyled></ImgInfoStyled>
          </InfoSectionInfoImgContainer>
          <LinksSectionPart1Container>
              <LinksSectionTitlesContainer>
                  <LinksSectionTitle>Sugerencias</LinksSectionTitle>
              </LinksSectionTitlesContainer>
              <LinksContainer >
                  <Card  style={{ width: '25rem', marginBottom: "10px", textAlign:"center" }}>
                    <Card.Body>
                      <Card.Text style={{fontSize:"14px"}}>Podrá enviar su sugerencia a: terapiahzb2020@gmail.com</Card.Text>
                      <Card.Link style={{fontSize:"14px"}} href="mailto:terapiahzb2020@gmail.com">O hacer click acá</Card.Link>
                    </Card.Body>
                  </Card>
              </LinksContainer>

              <LinksSectionTitlesContainer myref={legalRef}>
                  <LinksSectionTitle>Derechos Legales de los pacientes</LinksSectionTitle>
              </LinksSectionTitlesContainer>
              <LinksContainer>
                  <Card style={{ width: '16rem', marginBottom: "10px", textAlign:"center"}}>
                    <Card.Body>
                      <Card.Link style={{fontSize:"14px"}} href={pdf}>Ver derechos legales</Card.Link>
                    </Card.Body>
                  </Card>
              </LinksContainer>

              <LinksSectionTitlesContainer myref={questRef}>
                  <LinksSectionTitle>Encuesta de calidad de servicio</LinksSectionTitle>
              </LinksSectionTitlesContainer>
              <LinksContainer>
                  <Card style={{ width: '16rem', marginBottom: "10px", textAlign:"center", marginBottom:"10px"}}>
                    <Card.Body>
                      <Card.Link style={{fontSize:"14px"}} href="https://docs.google.com/forms/d/e/1FAIpQLSfpqkxkwNhE5S6MFocQCRckBkLzprWbdUzY6TEmz_EMKfiqfw/viewform?usp=sf_link">Ingresar a la encuesta</Card.Link>
                    </Card.Body>
                  </Card>
              </LinksContainer>
          </LinksSectionPart1Container>
        </LinksSectionPart2Container>

      </LinksSectionContainer>
      </>
    );
  }