import { HomeHeader } from "../components/header";
import { TitleSection } from "../components/titlesection";
import { TitleMovil } from "../components/titlesmovil";
import { InfoSection } from "../components/infosection";
import { HomeContainer } from "../UI/container";
import { LinksSection } from "../components/linkssection";

import React from "react";
import { useRef, useState, useEffect, useLayoutEffect  } from "react";



export function UTI() {

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useLayoutEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.matchMedia('(max-width: 768px)').matches);
    };

    handleResize(); // Establecer el estado inicial al cargar la página

    // Agregar un listener para detectar cambios en el tamaño de la ventana
    window.addEventListener('resize', handleResize);

    // Limpiar el listener cuando el componente se desmonte
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const InfoSectionRef = useRef(null);
  const TitleSectionRef = useRef(null);
  const PromptSectionRef = useRef(null);
  const LegalSectionRef = useRef(null);
  const QuestSectionRef = useRef(null);

  

  return (
    <>   
      <HomeHeader  infoSectionReference={InfoSectionRef} titleSectionReference={TitleSectionRef} promptSectionReference={PromptSectionRef} legalSectionReference={LegalSectionRef} questSectionReference={QuestSectionRef}/>
      <HomeContainer>
        {isSmallScreen ? <TitleMovil titleSectionRef={TitleSectionRef} /> : <TitleSection titleSectionRef={TitleSectionRef} />}
        <InfoSection infoRef={InfoSectionRef} />
        <LinksSection promptRef={PromptSectionRef} legalRef={LegalSectionRef} questRef={QuestSectionRef} />
      </HomeContainer>
    </> 
  );
}