import { createRoot } from "react-dom/client";
import { MyRoutes } from "./router";
import { BrowserRouter } from "react-router-dom";

const container = document.getElementById("app");
const root = createRoot(container)
root.render( 
  <BrowserRouter> 
    <MyRoutes></MyRoutes>
  </BrowserRouter> 
);