
import { TitleSectionContainer, TitleSectionTitlesContainer, TitleSectionArrowContainer, TitleSectionMoreInfoContainer, TitleSectionHospiImgCointener } from "../UI/container";
import { TitleSectionTitle, TitleSectionParagraph, TitleMoreInfo, TitleMoreInfoTitle } from "../UI/title";

import { ImgArrow, ImgHospi } from "../UI/img";
import { Container, Row, Col } from 'react-bootstrap';

import { styled } from "styled-components";

const OwnRow = styled(Row)`
  @media screen and (max-width: 768px){
      display: flex;
     }
`


export function TitleSection({titleSectionRef}) {
    return (
      <>
        <TitleSectionContainer myref={titleSectionRef}>
        <Container>
          <Row>
            <Col md={6}>
              <Row>
                <Col>
                  <TitleSectionTitlesContainer>
                    <TitleSectionTitle> Hospital Zonal</TitleSectionTitle>
                    <TitleSectionTitle> Dr Ramón Carrillo</TitleSectionTitle>
                    <TitleSectionParagraph>Servicio de Terapia Intensiva</TitleSectionParagraph>
                  </TitleSectionTitlesContainer>
                </Col>
              </Row>
              <OwnRow>
                <Col md={6}>
                  <TitleSectionMoreInfoContainer>
                    {/* <TitleMoreInfoTitle>Bienvenido!</TitleMoreInfoTitle> */}
                    <TitleMoreInfo>Aquí encontraras toda la información respecto a nuestro servicio</TitleMoreInfo>
                  </TitleSectionMoreInfoContainer>
                </Col>
                <Col md={6}>
                  <TitleSectionArrowContainer ><ImgArrow></ImgArrow></TitleSectionArrowContainer>
                </Col>
              </OwnRow>
            </Col>
            <Col md={6}>
              <TitleSectionHospiImgCointener ><ImgHospi></ImgHospi></TitleSectionHospiImgCointener> 
            </Col>
          </Row>
        </Container>
      </TitleSectionContainer>
      </>
    );
  }