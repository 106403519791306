import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { App } from "../App"
import { UTI } from "../pages/UTI";
import { Pdf } from "../pages/PDF";
function MyRoutes() {
  return (
    <Suspense>
      <Routes>
        <Route path="/" element={<App />}>
        </Route>
        <Route path="/UTI" element={<UTI />}>
        </Route>
        <Route path="/PDF" element={<Pdf />}>
        </Route>
      </Routes>
    </Suspense>
  );
}

export { MyRoutes };