import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import { ModalSupplies } from '../modal';
import ListGroup from 'react-bootstrap/ListGroup';

import { styled } from 'styled-components';

const CardStyled = styled(Card)`
   width: 50%;
   margin: 5px;
   text-align: center;
   @media screen and (max-width: 490px){
      width: 85%;
    }
`
const DivStyled = styled.div`
   display:flex;
   flex-direction:row;
   margin-top:10px;
   @media screen and (max-width: 430px){
      flex-direction: column;
      align-items: center;
   }
`

export function InfoSectionCard({title, tel, intern }) {
  return (
    <Card style={{ width: '18rem', margin: "5px", textAlign:"center" }}>
      <Card.Header>{title}</Card.Header>
      <Card.Body>
        <Card.Text>
          <strong>{tel}</strong>
          <p></p>
          <strong>{intern}</strong>
        </Card.Text>
      </Card.Body>
    </Card>
  );
}
export function InfoSectionRefCard({title, referents}) {
  return (
    <Card style={{ width: '18rem', margin: "5px", textAlign:"center" }}>
      <Card.Header>{title}</Card.Header>
      {referents.map((ref)=>{
        return(
        <Card.Body>
          <Card.Title>{ref.title}</Card.Title>
          <Card.Text>
            {ref.name}
          </Card.Text>
        </Card.Body>
        )
      })}
    </Card>
  );
}
export function InfoSectionTripleCard({titleOne, infoOneOne, infoOneTwo, titleTwo, infoTwoOne, infoTwoTwo }) {
  return (
    <Card style={{ width: '18rem', margin: "5px", textAlign:"center" }}>
      <Card.Header><strong>{titleOne}</strong></Card.Header>
      <Card.Body>
        <Card.Title>{infoOneOne}</Card.Title>
        <Card.Title>{infoOneTwo}</Card.Title> 
      </Card.Body>
      <Card.Header><strong>{titleTwo}</strong></Card.Header>
      <Card.Body>
        <Card.Title>{infoTwoOne}</Card.Title>
        <Card.Title>{infoTwoTwo}</Card.Title> 
      </Card.Body>
      <Card.Header><strong>Información adicional</strong></Card.Header>
      <Card.Body>
        <p>Se solicita a las familias numerosas no ser más de 2 familiares por paciente para recibir el informe.</p>
        <p>El informe médico podrá ser postergado o suspendido según la necesidad de atención a los pacientes internados, siendo estos la prioridad de atención.</p>
        <p>En caso de ser necesario, el médico de guardia llamara a los familiares para información extra fuera de este horario. Por favor, deje un teléfono activo.</p>
        <p>Se solicita traer fotocopia del DNI del paciente y/o carnet de obra social dentro de las 24 hs de internación.</p>
      </Card.Body>
    </Card>
  );
}
export function InfoSectionCard1({title, paragraphsTitle, paragraphs}){
  return (
    <CardStyled >
      <Card.Header>{title}</Card.Header>
      <Card.Body>
        <Card.Text>
          <strong>{paragraphsTitle}</strong>
          <p></p>
          <strong>{paragraphs}</strong>
        </Card.Text>
      </Card.Body>
    </CardStyled>
  );
}
export function InfoSectionCard2({title, paragraphsTitle, paragraphs}){
  return (
    <CardStyled >
      <Card.Header>{title}</Card.Header>
      <Card.Body style={{display:"flex", justifyContent:"center", alignItems:"center" }}>
        <Card.Text>
          <strong>{paragraphsTitle}</strong>
          <p></p>
          <strong>{paragraphs}</strong>
        </Card.Text>
      </Card.Body>
    </CardStyled>
  );
}
export function InfoSectionCard3({title, paragraphsTitle, paragraphs}){
  return (
    <Card style={{ width: '50%', margin: "5px", textAlign:"center" }}>
      <Card.Header>{title}</Card.Header>
      <Card.Body>
        <Card.Text>
          <strong>{paragraphsTitle}</strong>
          <p></p>
          <strong>{paragraphs}</strong>
        </Card.Text>
      </Card.Body>
    </Card>
  );
}
export function InfoSectionCardExtend(){
  return (
    <Card style={{ width: '99%', margin: "5px", textAlign:"center" }}>
      <Card.Header><strong>Información adicional</strong></Card.Header>
      <Card.Body>
        <p>Se solicita a las familias numerosas no ser más de 2 familiares por paciente para recibir el informe.</p>
        <p>El informe médico podrá ser postergado o suspendido según la necesidad de atención a los pacientes internados, siendo estos la prioridad de atención.</p>
        <p>En caso de ser necesario, el médico de guardia llamara a los familiares para información extra fuera de este horario. Por favor, deje un teléfono activo.</p>
        <p>Se solicita traer fotocopia del DNI del paciente y/o carnet de obra social dentro de las 24 hs de internación.</p>
      </Card.Body>
    </Card>
  );
}
export function InfoSectionCardSuppliesExtend(){
  return (
    <Card style={{ width: '100%', margin: "5px", textAlign:"center" }}>
      <Card.Header><strong>Insumos necesarios para la atención de su familiar</strong></Card.Header>
      <Card.Body>
        <strong>Cuando ingresa su familiar a terapia intensiva le solicitamos de forma semanal los insumos abajo solicitados:</strong>
        <div style={{display:"flex", flexDirection:"row", marginTop:"10px"}}>
          <p></p>
          <div style={{width:"50%"}}>
            <ListGroup variant="flush">
              <ListGroup.Item>Elementos de higiene</ListGroup.Item>
              <ListGroup.Item>Enjuague bucal con clorexidina (placout, periobacter)</ListGroup.Item>
              <ListGroup.Item>Cepillo de dientes pediatrico</ListGroup.Item>
              <ListGroup.Item>Desodorante aerosol</ListGroup.Item>
              <ListGroup.Item>Pañales adultos</ListGroup.Item>
            </ListGroup>
          </div>
          <div style={{width:"50%"}}>
            <ListGroup variant="flush">
              <ListGroup.Item>Shampoo y acondicionador</ListGroup.Item>
              <ListGroup.Item>Peine</ListGroup.Item>
              <ListGroup.Item>Maquina de afeitar</ListGroup.Item>
              <ListGroup.Item>Crema corporal humectante (dermaglos, crema base, factor AE emulsionante)</ListGroup.Item>
            </ListGroup>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}
export function InfoSectionCardSupplies({title, handleShow, handleClose, show }) {

  return (
    <Card style={{ width: '18rem', margin: "5px", textAlign:"center"}}>
      <Card.Header>{title}</Card.Header>
      <Card.Body>
        <Card.Text>
          <p></p>
           <Link onClick={handleShow}>Ver todos </Link>
           <ModalSupplies show={show} handleClose={handleClose}></ModalSupplies>
        </Card.Text>
      </Card.Body>
    </Card>
  );
}